import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/wolfymaster.com/wolfymaster.com/src/templates/subpageTemplate.js";
import { Col, Row, Container, Button } from "react-bootstrap";
import Gallery from 'react-grid-gallery';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="project-header" style={{
      color: "white",
      display: "flex",
      height: "600px",
      alignItems: "flex-start",
      flex: "0 0 100%",
      flexDirection: "column",
      justifyContent: "center",
      background: "url('https://cdn.pixabay.com/photo/2015/01/12/11/44/board-597238_960_720.jpg')",
      backgroundSize: "100%"
    }}>
    <div className="title" style={{
        fontSize: "38px",
        paddingLeft: "20%"
      }}>NWPA Tech</div>
    <div className="description" style={{
        fontSize: "18px",
        paddingLeft: "20%"
      }}>Doing something for the sake of doing somethingggg!</div>
    <div className="links" style={{
        margin: "60px auto -120px",
        textAlign: "center",
        width: "100%"
      }}>
        <Button variant="outline-light" size="lg" href="https://github.com" target="_blank" mdxType="Button">View on Github</Button>&nbsp; &nbsp;
        <Button variant="outline-light" size="lg" href="https://www.nwpa.tech" target="_blank" mdxType="Button">View Website</Button>
    </div>
    </div>
    <Container style={{
      padding: "50px 0"
    }} mdxType="Container">
    <Row mdxType="Row">
        <Col lg={{
          span: 10,
          offset: 1
        }} mdxType="Col">
            <div style={{
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "bold",
            padding: "20px 0"
          }}>Developers First</div>
            <div style={{
            textAlign: "center",
            fontSize: "18px"
          }}>
            We believe that payments is a problem rooted in code, not finance. We obsessively seek out elegant, composable abstractions that enable robust, scalable, flexible integrations. Because we eliminate needless complexity and extraneous details, you can get up and running with Stripe in just a couple of minutes.
            </div>
        </Col>
    </Row>
    </Container>
    {
      /* COMMENTED OUT FOR NOW
      <Container style={{ paddingBottom:"50px" }}>
         <Row>
             <Col>
                 <Gallery images={[{
                                     src: "http://placehold.it/300x300",
                                     thumbnail: "http://placehold.it/600x500",
                                     thumbnailWidth: 600,
                                     thumbnailHeight: 600,
                                     isSelected: false,
                                 },{
                                       src: "http://placehold.it/500x500",
                                       thumbnail: "http://placehold.it/500x500",
                                       thumbnailWidth: 500,
                                       thumbnailHeight: 500,
                                       isSelected: false,
                                   },
                               {
                                   src: "http://placehold.it/600x600",
                                   thumbnail: "http://placehold.it/600x900",
                                   thumbnailWidth: 900,
                                   thumbnailHeight: 500,
                                   isSelected: false,
                               },{
                                   src: "http://placehold.it/500x500",
                                   thumbnail: "http://placehold.it/500x500",
                                   thumbnailWidth: 500,
                                   thumbnailHeight: 500,
                                   isSelected: false,
                               },
                               {
                                   src: "http://placehold.it/600x600",
                                   thumbnail: "http://placehold.it/600x900",
                                   thumbnailWidth: 900,
                                   thumbnailHeight: 500,
                                   isSelected: false,
                               }]}
                     enableImageSelection={false}
                     rowHeight={250}
                     maxRows={3}
                    />
             </Col>
         </Row>
      </Container>
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      